<template>
	<div class="full-height pa-10-20">
		<h6>{{ program.name }}</h6>
		<div
			class="flex-column overflow-y-auto height-8"
		>
			<ul
				class="pa-10 justify-start bg-white"
			>
				<li
					v-for="(category, index) in terms_category"
					:key="'category_' + index"
					class="pa-10 tab cursor-pointer"
					:class="{ on: $route.query.category == category.type }"
					@click="setCategory(category.type)"
				>{{ category.name }}</li>
			</ul>

			<div class="mt-10 full-height justify-space-between overflow-y-auto full-width">
				<div class="pa-10 flex-1 full-height flex-column mr-10 bg-white width-50" >
					<div class="justify-space-between top-line-identify ptb-10 under-line" >
						<div class="size-px-12 line-height-2 position-relative " style="z-index: 999">
							<template
								v-if="input_type == 'view'"
							>
								<input placeholder="시행일자" class="box pa-3-10 width-80px text-center " readonly />
								<input v-model="item.sdate" placeholder="예약일" class="box pa-3-10 bg-white mr-10 text-center" readonly @click="is_data_pick = !is_data_pick"/>
								<template v-if="item.edate">
									<input placeholder="변경일자" class="box pa-3-10 width-80px text-center " readonly />
									<input v-model="item.edate" class="box pa-3-10 bg-white text-center" readonly />
								</template>
							</template>
							<template
								v-else
							>
								<input placeholder="시행일자" class="box pa-3-10 width-25 text-center " readonly />
								<input v-model="item.date" placeholder="예약일" class="input-box-inline pa-3-10 bg-white text-center" readonly @click="is_data_pick = !is_data_pick"/>
								<v-date-picker
									v-if="is_data_pick"
									v-model="item.date"
									no-title
									scrollable
									class="position-absolute"
									style="top: 40px; left: 0px; z-index: 30"
									@change="is_data_pick = false"
								></v-date-picker>
							</template>
						</div>

						<div v-if="is_data_pick"  class="position-fixed width-100 full-height " style="left: 0; top: 0; z-index: 998;" @click="is_data_pick = false"></div>

						<div>
							<button
								v-if="input_type == 'view'"
								class="bg-identify pa-5-10"
								@click="input_type = 'modify'"
							>이용약관 변경</button>
							<button
								v-if="input_type == 'new' || input_type == 'modify'"
								class="bg-identify pa-5-10"
								@click="save"
							>저장</button>
							<button
								v-if="input_type == 'modify'"
								class="bg-identify-outline pa-5-10 ml-10"
								@click="input_type = 'view'"
							>취소</button>
						</div>
					</div>

					<div class="full-height overflow-y-auto ">
						<Viewer
							v-if="input_type == 'view'"
							class="pa-10  full-height"
							:initialValue="item.content"
						/>
						<editor
							v-if="input_type == 'modify'"
							height="440px"
							initialEditType="markdown"
							ref="content"
							class="text-left "
							:initialValue="item.content"
						/>
						<editor
							v-if="input_type == 'new'"
							height="440px"
							initialEditType="markdown"
							ref="content"
							class="text-left "
						/>
					</div>
				</div>
				<div
					class="pa-10 flex-1 full-height bg-white flex-column width-50"
				>
					<div
						class="  justify-space-between top-line-identify ptb-10 under-line"
					>
						<div class="size-px-12 line-height-2">
							이전 약관
						</div>
					</div>

					<div
						class="full-height flex-column"
					>
						<table
							v-if="items_history.length > 0"
							class="mt-10 table"
						>
							<thead>
								<tr>
									<th>시행일자</th>
									<th>약관</th>
									<th>작성자</th>
									<th>등록일</th>
								</tr>
							</thead>
							<tbody>
								<tr
									v-for="(history, index) in items_history"
									:key="'history_' + index"
									:class="{ on: item.idx == history.idx}"
									class="cursor-pointer"

									@click="toDetail(history)"
								>
									<td>{{ history.date }}</td>
									<td class="text-left">{{ history.type }}</td>
									<td>{{ history.name }}</td>
									<td>{{ history.regDate | transDate }}</td>
								</tr>
							</tbody>
						</table>
						<div
							v-else
							class="full-height text-center  flex-column justify-center overflow-y-auto"
						>
                            <div class="text-center pa-20">
                                <v-icon
                                    class="size-px-48 color-icon"
                                >mdi mdi-cloud-off-outline</v-icon>
                                <br/>
                                <br/>
                                <div class="font-weight-bold size-px-24">No Data</div>
                            </div>
						</div>
						<Pagination
							:options="search"

							@click="getData"
						></Pagination>
					</div>
				</div>
			</div>
		</div>
		<Modal
			:is_modal="is_modal"
			:option="modal_option"
			bottom_class=""

			@close="clear"
			@click="clear"
		>
			<div
				slot="modal-content"
			>
				<div class="justify-space-between ptb-10">
					<div class="font-weight-bold">{{ item_previous.type }}</div>
					<div>시행일자: {{ item_previous.date }}</div>
				</div>
				<Viewer
					v-if="item_previous.content"
					class="pa-10 bg-gray-light text-left mt-10"
					:initialValue="item_previous.content"
					style="height: 350px"
				/>
			</div>
		</Modal>
	</div>
</template>

<script>

	import '@toast-ui/editor/dist/toastui-editor.css';
	import '@toast-ui/editor/dist/toastui-editor-viewer.css';
	import { Viewer } from "@toast-ui/vue-editor";
	import { Editor } from "@toast-ui/vue-editor";
	import Modal from "@/components/Modal";
	import Pagination from "@/components/Pagination";

	export default {
		name: 'TermsList'
		,components: {Pagination, Modal, Viewer, Editor }
		,data: function(){
			return {
				program: {
					name: '약관'
					,top: true
				}
				,is_data_pick: false
				,items: []
				,item: {}
				,item_input: {}
				,item_previous: {}
				,items_category: []
				,items_history: []
				,search: {
					page: this.$route.query.page ? this.$route.query.page : 1
					,size: this.$route.query.size ? this.$route.query.size: 10
					, category: this.$route.query.category ? this.$route.query.category : ''
                    ,total_count: 0
				}
				,terms_category: []
				,is_modify: false
				,input_type: 'new'
				,is_modal: false
				,modal_option: {
					title: '이전 약관'
					,top: true
					,bottom: true
					,is_cancel: true
				}
			}
		}
		,computed: {
			type_name: function(){
				let name = ''
				for(let i = 0; i < this.terms_category.length; i++) {
					if (this.$route.query.category == this.terms_category[i].type) {
						name = this.terms_category[i].name
						break
					}
				}
				return name
			}
		}
		,methods: {
			getData: async function(){
				try{
					this.$layout.onLoading()
					const result = await this.$Axios({
						method: 'get'
						,url: 'terms/' + this.$route.query.category
					})

					if(result.success){
						if(result.data){
							this.item = result.data
							this.item.date = this.item.sdate.replaceAll('.', '-')
							this.input_type = 'view'
                            this.search.total_count = result.data.totalCount
						}else{
							this.item = {}
							this.input_type = 'new'
						}
					}else{
						this.$layout.setNotify( { type: 'error', message: result.message})
					}
				}catch(e){
					this.$layout.setNotify( { type: 'error', message: this.$language.common.error})
					console.log(e)
				}finally {
					this.$layout.offLoading()
				}
			}
			,save: async function(){
				this.item.content = this.$refs.content.invoke("getMarkdown")
				try{
					this.$layout.onLoading()
					const result = await this.$Axios({
						method: 'post'
						,url: 'terms'
						, data: {
							type: this.$route.query.category
							,content: this.item.content
							,date: this.item.date ? this.item.date.replaceAll('-', '.') : ''
						}
					})

					if(result.success){
						this.$layout.setNotify( { type: 'success', message: result.message})
						this.$router.go(this.$router.currentRoute)
					}else{
						this.$layout.setNotify( { type: 'error', message: result.message})
					}
				}catch(e){
					this.$layout.setNotify( { type: 'error', message: this.$language.common.error})
					console.log(e)
				}finally {
					this.$layout.offLoading()
				}
			}
			,getCategory: async function(){
				try{
					this.$layout.onLoading()
					const result = await this.$Axios({
						method: 'get'
						,url: 'terms'
					})

					if(result.success){
						this.$set(this, 'terms_category', result.data)

						if(!this.$route.query.category){
							this.$layout.push( { name: 'TermsList', query: { category: result.data[0].type }})
						}
					}else{
						this.$layout.setNotify( { type: 'error', message: result.message})
					}
				}catch(e){
					this.$layout.setNotify( { type: 'error', message: this.$language.common.error})
					console.log(e)
				}finally {
					this.$layout.offLoading()
				}
			}
			,getHistory: async function(){
				try{
					this.$layout.onLoading()
					const result = await this.$Axios({
						method: 'get'
						,url: 'terms/previousList'
						,data: this.search
					})

					if(result.success){
						this.items_history = result.data
					}else{
						this.$layout.setNotify( { type: 'error', message: result.message})
					}
				}catch(e){
					this.$layout.setNotify( { type: 'error', message: this.$language.common.error})
					console.log(e)
				}finally {
					this.$layout.offLoading()
				}
			}
			,setCategory: function(category){
				this.$layout.push( { name: this.$route.name, query: { category: category}})
				this.getHistory()
			}
			,toHistory: function(){
				this.$layout.push( {name: 'TermsHistory'})
			}
			,toDetail: async function(history){
				try{
					this.$layout.onLoading()
					const result = await this.$Axios({
						method: 'get'
						,url: 'terms/previous/' + history.idx
					})

					if(result.success){
						this.item_previous = result.data
						this.is_modal = true
					}else{
						this.$layout.setNotify( { type: 'error', message: result.message})
					}
				}catch(e){
					this.$layout.setNotify( { type: 'error', message: this.$language.common.error})
					console.log(e)
				}finally {
					this.$layout.offLoading()
				}
			}
			,clear: function(){
				this.is_modal = false
			}
		}
		,created() {
            this.$layout = this.$root.$children[0].$children[0]
            this.$layout.onLoad(this.program)
			this.getCategory()
		}
		,watch: {
			'$route.query.category': {
				immediate: true
				,handler: function (call) {
                    if (!this.$layout) {
                        this.$layout = this.$root.$children[0].$children[0]
                        this.user = this.$layout.user
                    }
					if(call) {
						this.getData()
						this.getHistory()
					}
				}
			}
		}
	}
</script>

<style>

.toastui-editor-defaultUI-toolbar button {
	margin: 7px 0px;
}
</style>